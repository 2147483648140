import React, { useEffect, useLayoutEffect, useState } from "react";

import "./App.css";
import ProcessBarSection from "./components/ProcessBarSection/PopupBoxSection";
import axios from "axios";
import { getStorageItem, setStorageItem } from "./utils/storageFunction";
import { detectDeviceType, getApiCallDetailsPath, getZinoDomainAppData } from "./utils/apiCallFunction";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import WelcomePage from "./pages/welcome/WelcomePage";
import LoginPage from "./pages/login/LoginPage";
import ForgetPassword from "./pages/login/ForgetPassword";
import ResetPassword from "./pages/login/ResetPassword";
import OTPScreen from "./pages/login/OTPScreen";
import Page404 from "./pages/other/Page404";
import AppList from "./pages/AppList";
import NoAccessPage from "./pages/NoAccessPage";
import { setIsMobileUserAgent, updateUserDataFunction } from "./redux/userview/userviewaction";
import { useDispatch, useSelector } from "react-redux";
import LogoutPage from "./pages/login/LogoutPage";
import PublicScreens from "./pages/publicScreens/PublicScreens";

function App() {
  const { user, authenticate } = useSelector((state) => state.userviewstore);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const loadDomain = async () => {
    try {
      setIsLoading(true);
      let resData = {
        favicon: "",
        title: "Zino",
        app_logo: ""
      };
      if (getApiCallDetailsPath()) {
        let { data } = await axios.get(`${getApiCallDetailsPath()}/api/v1/web-tab-title/${window.location.host}`);
        resData = data?.data || {
          favicon: "",
          title: "Zino",
          app_logo: ""
        };
      }
      if (!resData.title) {
        resData.title = "Zino";
      }
      setStorageItem("Zino_domain_app_data", JSON.stringify(resData));
    } catch (error) {
      setStorageItem(
        "Zino_domain_app_data",
        JSON.stringify({
          favicon: "",
          title: "Zino",
          app_logo: ""
        })
      );
    } finally {
      let fetchedData = getZinoDomainAppData();
      //new title
      document.title = fetchedData.title;
      //new favicon
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      if (fetchedData.favicon) {
        newFavicon.href = `${getApiCallDetailsPath()}/api/v1/public/${fetchedData.favicon}`;
      } else {
        newFavicon.href = `/zino-logo.png`;
      }
      const oldFavicon = document.querySelector('link[rel="icon"]');
      if (oldFavicon) {
        oldFavicon.remove();
      }
      document.head.appendChild(newFavicon);

      //new apple logo
      const newAppleIcon = document.createElement("link");
      newAppleIcon.rel = "apple-touch-icon";
      if (fetchedData.app_logo) {
        newAppleIcon.href = `${getApiCallDetailsPath()}/api/v1/public/${fetchedData.app_logo}`;
      } else {
        newAppleIcon.href = `/zino-logo.png`;
      }
      const oldAppleIcon = document.querySelector('link[rel="apple-touch-icon"]');
      if (oldAppleIcon) {
        oldAppleIcon.remove();
      }
      document.head.appendChild(newAppleIcon);

      if (fetchedData.app_logo) {
        const manifest = {
          short_name: fetchedData.title,
          name: fetchedData.title,
          icons: [
            {
              src: fetchedData.app_logo
                ? `${getApiCallDetailsPath()}/api/v1/public/${fetchedData.app_logo}`
                : `/zino-logo.png`,
              sizes: "192X192 512x512",
              type: "image/png"
            }
          ],
          start_url: window.location.origin,
          display: "standalone",
          theme_color: "#000000",
          background_color: "#ffffff"
        };
        const manifestLink = document.getElementById("new_manifest");
        manifestLink.href = `data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(manifest))}`;
      }
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    let doamin = "./";
    let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";
    if (environmentValue === "local") {
      doamin = "/";
    }

    const link = document.createElement("link");
    link.rel = "manifest";
    link.href = `${doamin}manifest.json`;
    link.id = `new_manifest`;
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useLayoutEffect(() => {
    loadDomain();
    let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";
    if (environmentValue === "local") {
      let Zino_Is_Mobile_User_Agent = getStorageItem("Zino_Is_Mobile_User_Agent");
      if (Zino_Is_Mobile_User_Agent) {
        dispatch(setIsMobileUserAgent(Zino_Is_Mobile_User_Agent === "true"));
      } else {
        dispatch(setIsMobileUserAgent(detectDeviceType() === "Mobile"));
      }
    } else {
      dispatch(setIsMobileUserAgent(detectDeviceType() === "Mobile"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (["/resetPassword", "/logout", "/public/applist"].includes(location.pathname)) {
      setIsLoading(false);
    } else if (!authenticate && !user) {
      if (!authenticate) dispatch(updateUserDataFunction());
    } else {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, user]);

  if (isLoading) {
    return <ProcessBarSection />;
  } else {
    return (
      <>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/forgotPassword" element={<ForgetPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/otp" element={<OTPScreen />} />
          <Route path="/page404" element={<Page404 />} />
          <Route path="/applist" element={<AppList />} />
          <Route path="/public/applist" element={<PublicScreens />} />
          <Route path="/noaccess" element={<NoAccessPage />} />
          <Route path="/*" element={<Navigate to="/login" replace={true} />} />
        </Routes>
      </>
    );
  }
}

export default App;
