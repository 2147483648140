import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callUserLogoutFunction } from "../redux/userview/userviewaction";
import { useNavigate } from "react-router-dom";
import noAccess from "../Images/noAccess.svg";

const NoAccessPage = () => {
  const { user } = useSelector((state) => state.userviewstore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.loginFlag) {
      dispatch(callUserLogoutFunction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="no_access_outer">
      <img src={noAccess} alt="img" />
      <h2 className="app_text_bold mb_20">No access</h2>

      <button style={{ width: "340px" }} className="zino_btn_primary login mb_20" onClick={() => navigate("/login")}>
        Login
      </button>
    </div>
  );
};

export default NoAccessPage;
