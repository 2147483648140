import { detectDeviceType } from "../../utils/apiCallFunction";
import { ADD_USER, USER_TOKEN, REMOVE_USER, USER_AUTHENTICATE, LOGIN_CALL, MOBILE_USER_AGENT } from "./userviewaction";

// STATE
export const initState = {
  isMobileUserAgent: detectDeviceType() === "Mobile"
};

// REDUCER
export const userviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ADD_USER:
      return {
        ...state,
        user: payload
      };

    case USER_TOKEN:
      return {
        ...state,
        token: payload
      };

    case USER_AUTHENTICATE:
      return {
        ...state,
        authenticate: payload
      };

    case LOGIN_CALL:
      return {
        ...state,
        logincall: payload
      };

    case MOBILE_USER_AGENT:
      return {
        ...state,
        isMobileUserAgent: payload
      };

    case REMOVE_USER:
      return { user: { loginFlag: false }, isMobileUserAgent: state.isMobileUserAgent };

    default:
      return state;
  }
};
