import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { callUserLogoutFunction } from "../../redux/userview/userviewaction";
import { getApiCallHeadersData, getStorageItem } from "../../utils/storageFunction";
import axios from "axios";
import toast from "react-hot-toast";
import { getApiCallHostPath } from "../../utils/apiCallFunction";

function Navbar({ rootType, headerConfig }) {
  const navigate = useNavigate();
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);

  const handleChangePasswordPopup = () => {
    setShowChangePasswordPopup(true);
  };

  return (
    <>
      <nav
        className="app_navbar"
        style={{
          position: "sticky",
          justifyContent: "flex-end"
        }}
      >
        <div className="profile_dropdown_container">
          <div className="profile_dropdown">
            {rootType === "public" ? (
              <>
                <div title="Open profile" className="nav_icon_outer" onClick={() => navigate("login")}>
                  <span className="material-symbols-outlined">login</span>
                </div>
              </>
            ) : (
              <>
                <div title="Open profile" className="nav_icon_outer">
                  <span className="material-symbols-outlined">account_circle</span>
                </div>
                <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
              </>
            )}
          </div>
        </div>
      </nav>
      {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
    </>
  );
}

export default Navbar;

const UserPopupPrivate = ({ handleChangePasswordPopup, styleObject={} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userviewstore);

  useEffect(() => {
    if (user && !user.loginFlag) {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLogout = () => {
    dispatch(callUserLogoutFunction());
  };

  let isPublicFlag = true;

  let appIsPublic = getStorageItem("Zino_app_is_public");
  if (!appIsPublic || appIsPublic?.toString() !== "true") {
    isPublicFlag = false;
  }

  return (
    <div className="profile_popover" style={styleObject}>
      <div title={user?.name}>
        <span>{user?.name}</span>
      </div>
      <div title={user?.email}>
        <span>{user?.email}</span>
      </div>
      <div className="cursor-pointer" onClick={() => navigate("/applist")}>
        <span>Apps List</span>
      </div>
      {isPublicFlag && (
        <div className="cursor-pointer" onClick={() => navigate("/zadmin/metrics")}>
          <span>Admin Page</span>
        </div>
      )}
      <div className="cursor-pointer" onClick={handleChangePasswordPopup}>
        <span>Change Password</span>
      </div>
      <div className="cursor-pointer" onClick={handleLogout}>
        <span>Log out</span>
      </div>
      <div>
        <span>Version : {process.env.REACT_APP_VERSION}</span>
      </div>
    </div>
  );
};

const ChangePasswordPopup = ({ setShowChangePasswordPopup }) => {
  const [existingPassword, setExistingPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [errorState, setErrorState] = useState({
    existing: false,
    new: false,
    reEnter: false
  });
  const handleErrors = () => {
    let tempObj = {};
    tempObj = {
      existing: existingPassword?.length < 4,
      new: newPassword?.length < 4,
      reEnter: reEnterNewPassword !== newPassword
    };
    setErrorState({
      existing: existingPassword?.length < 4,
      new: newPassword?.length < 4,
      reEnter: reEnterNewPassword !== newPassword
    });
    return tempObj;
  };

  const handleChangePassword = async () => {
    const tempObj = handleErrors();
    if (!tempObj?.existing && !tempObj?.new && !tempObj?.reEnter) {
      try {
        await axios.put(
          `${getApiCallHostPath()}/api/v1/loginPassword`,
          { existing_password: existingPassword, new_password: newPassword },
          { headers: getApiCallHeadersData() }
        );
        toast.success("Change Password done successfully");
        setShowChangePasswordPopup(false);
      } catch (error) {
        setErrorState({
          existing: error?.response?.data?.message,
          new: false,
          reEnter: false
        });
      }
    }
  };

  const handleChange =
    (type) =>
    ({ target: { value } }) => {
      switch (type) {
        case "existing":
          setExistingPassword(value);
          break;
        case "new":
          setNewPassword(value);
          break;
        case "reEnter":
          setReEnterNewPassword(value);
          break;
        default:
          break;
      }
    };

  return (
    <div className="password_outer_container">
      <div className="password_inner_container">
        <div className="password_header_container">
          <p>Change Password</p>
          <span className="material-symbols-outlined" onClick={() => setShowChangePasswordPopup(false)}>
            close
          </span>
        </div>
        <div className="password_body_container">
          <div className="password_input_container">
            <label className="password_input_label">Existing Password</label>
            <input
              className="zino_inputBox"
              placeholder="Enter existing password"
              value={existingPassword}
              onChange={handleChange("existing")}
            />
            {errorState?.existing && (
              <p className="errorMessage">
                {errorState?.existing === true ? "Please enter valid Password" : errorState?.existing}
              </p>
            )}
          </div>
          <div className="password_input_container">
            <label className="password_input_label">New Password</label>
            <input
              className="zino_inputBox"
              placeholder="Enter new password"
              value={newPassword}
              onChange={handleChange("new")}
            />
            {errorState?.new && <p className="errorMessage">Password contains atleast 4 characters</p>}
          </div>
          <div className="password_input_container">
            <label className="password_input_label">Re-enter new Password</label>
            <input
              className="zino_inputBox"
              placeholder="Re-enter new password"
              value={reEnterNewPassword}
              onChange={handleChange("reEnter")}
            />
            {errorState?.reEnter && <p className="errorMessage">Passwords mismatch!</p>}
          </div>
          <button className="zino_btn_primary" onClick={handleChangePassword}>
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
};
