import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { appviewreducer } from "./appview/appviewreducer";
import { userviewreducer } from "./userview/userviewreducer";

const enhancers = [];

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (typeof devToolsExtension === "function") {
  enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(applyMiddleware(thunk), ...enhancers);

const rootReducer = combineReducers({
  appviewstore: appviewreducer,
  userviewstore: userviewreducer
});

export const store = createStore(rootReducer, composedEnhancers);
