import axios from "axios";
import toast from "react-hot-toast";
import { getApiCallHeadersData, getStorageItem, removeStorageItem, setStorageItem } from "../../utils/storageFunction";
import { getApiCallHostPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";

export const LOGIN_CALL = "LOGIN_CALL";
export const ADD_USER = "ADD_USER";
export const USER_AUTHENTICATE = "USER_AUTHENTICATE";
export const USER_TOKEN = "USER_TOKEN";
export const REMOVE_USER = "REMOVE_USER";
export const MOBILE_USER_AGENT = "MOBILE_USER_AGENT";

export const setUserData = (payload) => ({
  type: ADD_USER,
  payload
});

export const setUserToken = (payload) => ({
  type: USER_TOKEN,
  payload
});

export const setAuthenticate = (payload) => ({
  type: USER_AUTHENTICATE,
  payload
});

export const setLoginCall = (payload) => ({
  type: LOGIN_CALL,
  payload
});

export const removeUserData = (payload) => ({
  type: REMOVE_USER,
  payload
});

export const setIsMobileUserAgent = (payload) => ({
  type: MOBILE_USER_AGENT,
  payload
});

export const callUserLoginFunction = (loginData) => async (dispatch) => {
  try {
    dispatch(setLoginCall(true));
    let userData;
    let { data } = await axios.post(`${getApiCallHostPath()}/api/v1/login`, loginData);
    userData = data;

    if (userData) {
      setStorageItem("Zino_app_user", userData.access_token);
      let flag = `${window.location.origin}/applist`;
      // let app_list = userData?.app_list || [];
      // if (app_list.length === 1) {
      //   let appItem = app_list[0];
      //   if (appItem.status === "live") {
      //     flag = `${window.location.origin}/home`;
      //     setStorageItem("Zino_app_id", `${appItem.id}`);
      //     setStorageItem("Zino_domain_web", `${appItem.domain_web}/`);
      //     setStorageItem("Zino_app_owner_domain", `${window.location.origin}`);
      //     setStorageItem("Zino_app_name", `${appItem.name}`);
      //     setStorageItem("Zino_app_is_public", `${appItem.is_admin}`);
      //     setStorageItem("selectedApp", JSON.stringify(appItem));
      //   }
      // }
      await dispatch(updateUserDataFunction(flag));
    } else {
      throw new Error("Failed to fetch user data.");
    }
  } catch (error) {
    dispatch(setAuthenticate(false));
    toast.error(error.response?.data?.message, toastErrorMessageStyle());
  } finally {
    dispatch(setLoginCall(false));
  }
};

export const updateUserDataFunction = (flag) => async (dispatch) => {
  const urlParams = new URLSearchParams(window.location.search);
  const user = urlParams.get("user");
  if (user) setStorageItem("Zino_app_user", user);

  if (!getStorageItem("Zino_app_user_go_to")) {
    if (flag) {
      setStorageItem("Zino_app_user_go_to", flag);
    } else {
      setStorageItem("Zino_app_user_go_to", `${window.location.origin}/noaccess`);
    }
  }

  let resetUser = false;
  let token = getStorageItem("Zino_app_user");
  if (token) {
    const decode = JSON.parse(atob(token.split(".")[1]));
    if (decode.exp >= Math.floor(Date.now() / 1000)) {
      dispatch(setUserToken(`Bearer ${token}`));
      dispatch(
        setUserData({
          login_user_id: decode?.user_uuid,
          name: decode?.name || "Zino",
          email: decode?.email || "zino@getzino.com",
          exp_time: decode?.exp || Math.floor(Date.now() / 1000) + 86400,
          token: token,
          loginFlag: true,
          goto_page: flag
        })
      );
      dispatch(setAuthenticate(true));
    } else {
      resetUser = true;
    }
  } else {
    resetUser = true;
  }
  if (resetUser) {
    dispatch(removeUserData());
    removeStorageItem("", ["Zino_app_user", "Zino_app_route", "Zino_domain_web", "Zino_app_user_go_to", "selectedApp"]);
    const startWithPattern = new RegExp(`^/public/.*$`, "i");
    if (!startWithPattern.test(window.location.pathname) && !["/", "/login"].includes(window.location.pathname)) {
      window.open("/login", "_self");
    }
  }

  return resetUser;
};

export const callUserLogoutFunction = () => async (dispatch) => {
  try {
    await axios({
      method: "post",
      url: `${getApiCallHostPath()}/api/v1/logout`,
      headers: getApiCallHeadersData()
    });
  } catch (error) {
    toast.error(error.response.data.error, toastErrorMessageStyle());
  } finally {
    dispatch(removeUserData());

    removeStorageItem("", [
      "Zino_app_user",
      "Zino_domain_web",
      "Zino_app_id",
      "Zino_app_name",
      "Zino_app_is_public",
      "Zino_app_theme_id",
      "Zino_app_user_go_to",
      "Zino_app_module_header",
      "Zino_app_user_go_to",
      "selectedApp"
    ]);
  }
};

export const validateOtp = (phoneNumber, otpValue) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${getApiCallHostPath()}/api/v1/login/otp/${phoneNumber}/verify/${otpValue}`);
    setStorageItem("Zino_app_user", data?.access_token);
    dispatch(updateUserDataFunction(`${window.location.origin}/applist`));
  } catch (error) {
    toast.error(error?.response?.data?.message || "something went wrong", toastErrorMessageStyle());
  } finally {
    dispatch(setLoginCall(false));
  }
};
