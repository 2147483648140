import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./LoginPage.css";

import { callUserLoginFunction, setIsMobileUserAgent } from "../../redux/userview/userviewaction";
import { getStorageItem, removeStorageItem, setStorageItem } from "../../utils/storageFunction";
import { getApiCallDetailsPath, getZinoDomainAppData } from "../../utils/apiCallFunction";
import loginPageImg from "../../Images/login_img.svg";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticate, logincall, user, isMobileUserAgent } = useSelector((state) => state.userviewstore);

  const [loginCredential, setLoginCredential] = useState({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";

  const handleLoginData = (e) => {
    setLoginCredential({
      ...loginCredential,
      [e.target.name]: e.target.value
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (loginCredential.email && loginCredential.password) {
      dispatch(callUserLoginFunction(loginCredential));
    }
  };

  const handleNavigate = () => {
    navigate("/forgotPassword");
  };

  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setDeferredPrompt(event);
    setShowInstallPrompt(true);
  };

  //install popup
  const installApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setShowInstallPrompt(false);
      });
    }
  };

  const removePopup = () => {
    setShowInstallPrompt(false);
  };

  useEffect(() => {
    if (authenticate && !logincall && getStorageItem("Zino_app_user")) {
      let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";
      let userGoto = user?.goto_page;
      if (userGoto && environmentValue !== "local") {
        userGoto = userGoto.split("/");
        let app_id = getStorageItem("Zino_app_id");
        if (app_id) {
          userGoto = `/${app_id}/${userGoto[userGoto.length - 1]}`;
        } else {
          userGoto = `/${userGoto[userGoto.length - 1]}`;
        }
        navigate(userGoto);
      }
      navigate("/applist");
    }
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Login`;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, logincall]);

  useEffect(() => {
    removeStorageItem("", [
      "Zino_public_app_id",
      "Zino_public_domain_web",
      "Zino_public_app_name",
      "Zino_app_id",
      "Zino_domain_web",
      "Zino_app_name",
      "Zino_app_theme_id",
      "Zino_app_user_go_to",
      "Zino_app_module_header",
      "selectedApp"
    ]);
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {environmentValue === "local" && (
        <div className="login_page_appbar_dev_hide">
          <label className="switch-mobile-desktop">
            <input
              className="switch-mobile-desktop-input"
              type="checkbox"
              checked={isMobileUserAgent}
              onChange={(e) => {
                dispatch(setIsMobileUserAgent(e.target.checked));
                setStorageItem("Zino_Is_Mobile_User_Agent", e.target.checked);
              }}
            />
            <span className="switch-mobile-desktop-slider"></span>
            <span className="material-symbols-outlined switch-desktop-slider">laptop_windows</span>
            <span className="material-symbols-outlined switch-mobile-slider">phone_android</span>
          </label>
        </div>
      )}
      {showInstallPrompt && isMobileUserAgent && (
        <div className="install-prompt">
          <p>Install app to your device for a better experience!</p>
          <div className="install-prompt-button-container">
            <button onClick={removePopup} className="zino_btn_outline_primary popup_button">
              Cancel
            </button>
            <button onClick={installApp} className="zino_btn_primary popup_button">
              Install
            </button>
          </div>
        </div>
      )}

      <div className={isMobileUserAgent ? "mobile_login_page_outer_wrap" : "login_page_outer_wrap"}>
        <div className="login_page_img_outer">
          <img
            className="login_page_img"
            src={
              getZinoDomainAppData()?.login_image
                ? `${getApiCallDetailsPath()}/api/v1/public/${getZinoDomainAppData()?.login_image}`
                : loginPageImg
            }
            alt="login img"
          ></img>
        </div>
        <div className="login_outer_container">
          <div className="login_inner_container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1 style={{ fontSize: "24px" }}>Login</h1>
              <button
                style={{ width: "124px", borderRadius: "20px" }}
                onClick={() => navigate("/public/applist")}
                className="zino_btn_outline_primary login"
              >
                <span className="material-symbols-outlined">account_circle</span>
                Guest User
              </button>
            </div>
            <form className="login_field" onSubmit={handleLogin}>
              <label className="zino_label">Email</label>
              <input
                type="email"
                value={loginCredential.email}
                name="email"
                onChange={handleLoginData}
                className="zino_inputBox"
                placeholder="Enter Email"
              />
              <label className="zino_label">Password</label>
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  value={loginCredential.password}
                  name="password"
                  onChange={handleLoginData}
                  className="zino_inputBox"
                  placeholder="Enter Password"
                />

                <div
                  onClick={() => setShowPassword((prevState) => !prevState)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "20px",
                    opacity: "0.7",
                    cursor: "pointer"
                  }}
                >
                  <span className="material-symbols-outlined">{showPassword ? "visibility" : "visibility_off"}</span>
                </div>
              </div>

              <div className="forget_password_container">
                <p className="forget_password_label " onClick={handleNavigate}>
                  Forgot Password?
                </p>
              </div>
              <button className="zino_btn_primary login" onClick={handleLogin} disabled={logincall}>
                Login
              </button>
              <p
                className="forget_password_label otp_submit"
                style={{ alignItems: "center" }}
                onClick={() => {
                  navigate("/otp");
                }}
              >
                Login with OTP
              </p>
              {/* <button style={{width: "42%", borderRadius: "20px"}} onClick={() => navigate("/public/applist")} className="zino_btn_outline_primary login" >
              <span className="material-symbols-outlined">account_circle</span>
              Guest User
            </button> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoginPage;
