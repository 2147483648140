import axios from "axios";
import toast from "react-hot-toast";
import { getApiCallHostPath, getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";

export const DASHBOARD_CONFIG_APP_DATA = "DASHBOARD_CONFIG_APP_DATA";
export const DASHBOARD_CONFIG_ORG_DATA = "DASHBOARD_CONFIG_ORG_DATA";
export const DASHBOARD_APPS_LIST_DATA = "DASHBOARD_APPS_LIST_DATA";
export const WORKFLOW_APP_OBJECT_DATA = "WORKFLOW_APP_OBJECT_DATA";
export const ACTION_JOB_RES_DATA = "ACTION_JOB_RES_DATA";
export const ERROR_SHOW = "ERROR_SHOW";
export const NOTIFICATIONS_ARRAY = "NOTIFICATIONS_ARRAY";
export const DASHBOARD_PUBLIC_APPS_LIST_DATA = "DASHBOARD_PUBLIC_APPS_LIST_DATA";

export const setWorkflowAppObjectData = (payload) => ({
  type: WORKFLOW_APP_OBJECT_DATA,
  payload
});

export const setDashboardAppConfig = (payload) => ({
  type: DASHBOARD_CONFIG_APP_DATA,
  payload
});

export const setDashboardAppsList = (payload) => ({
  type: DASHBOARD_APPS_LIST_DATA,
  payload
});

export const setDashboardPublicAppsList = (payload) => ({
  type: DASHBOARD_PUBLIC_APPS_LIST_DATA,
  payload
});

export const setNotificationsFunction = (payload) => ({
  type: NOTIFICATIONS_ARRAY,
  payload
});

export const setDashboardOrganizationConfig = (payload) => ({
  type: DASHBOARD_CONFIG_ORG_DATA,
  payload
});

export const setActionJobResData = (payload) => ({
  type: ACTION_JOB_RES_DATA,
  payload
});

export const setErrorShow = (payload) => ({
  type: ERROR_SHOW,
  payload
});

export const getAppsListFunction = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${getApiCallHostPath()}/api/v1/applist`, {
      headers: getApiCallHeadersData()
    });
    dispatch(setDashboardAppsList(data?.app_list || []));
  } catch (error) {
    toast.error(
      error.response?.data?.message || error.response?.data.error || "App not found",
      toastErrorMessageStyle()
    );
    // window.open("/page404", "_self");
    // window.history.back();
  }
};
export const getAppsListForPublicScreensFunction = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${getApiCallHostPath()}/api/v1/guest/applist`, {
      // headers: getApiCallHeadersData()
    });
    dispatch(setDashboardPublicAppsList(data?.app_list || []));
  } catch (error) {
    toast.error(
      error.response?.data?.message || error.response?.data.error || "App not found",
      toastErrorMessageStyle()
    );
    // window.open("/page404", "_self");
    // window.history.back();
  }
};

export const getNotificationsCountFunction = () => async () => {
  try {
    let { data } = await axios.get(`${getApiCallLocalPath()}api/v1/todo/notificationCount`, {
      headers: getApiCallHeadersData()
    });

    return data.data;
  } catch (error) {
    return 0;
  }
};
