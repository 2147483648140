import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./WelcomePage.css";
import { getZinoDomainAppData } from "../../utils/apiCallFunction";

const WelcomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"}`;
    navigate("/login");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default WelcomePage;
