import React, { useEffect } from "react";
import { removeStorageItem } from "../../utils/storageFunction";
import { useNavigate } from "react-router-dom";

const LogoutPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    removeStorageItem("", [
      "Zino_app_id",
      "Zino_domain_web",
      "Zino_app_name",
      "Zino_app_is_public",
      "Zino_app_module_header",
      "Zino_app_owner_domain",
      "Zino_app_data",
      "Zino_app_user",
      "selectedApp"
    ]);
    navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="zino_renderer_loader_box">
      <span className="zino_renderer_loader"></span>
    </div>
  );
};

export default LogoutPage;
