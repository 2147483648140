import React from "react";

import "../Pages.css";

function Page404() {
  const goToBackPage = () => {
    window.history.back();
  };
  return (
    <div className="error404_page_outerBody">
      <h1>Page Not Found</h1>
      <button className="zino_btn_outline_primary" onClick={goToBackPage}>
        Go To Home
      </button>
    </div>
  );
}

export default Page404;
