import { getStorageItem, setStorageItem } from "./storageFunction";

const getApiCallLocalPath = (type = "auth") => {
  if (type === "public") {
    let hostName = getStorageItem("Zino_public_domain_web");
    if (!hostName) {
      setStorageItem("Zino_public_domain_web", `${window.location.origin}/`);
      hostName = `${window.location.origin}/`;
    }
    return hostName;
  } else {
    let hostName = getStorageItem("Zino_domain_web");
    if (!hostName) {
      setStorageItem("Zino_domain_web", `${window.location.origin}/`);
      hostName = `${window.location.origin}/`;
    }
    return hostName;
  }
};

const getApiCallHostPath = () => {
  let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";
  if (environmentValue === "local") {
    let hostName = process.env.REACT_APP_AUTH_HOST;
    return hostName;
  } else {
    let hostName = window.location.origin;

    return hostName;
  }
};

const getApiCallDetailsPath = () => {
  let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";
  if (environmentValue === "local") {
    let hostName = process.env.REACT_APP_DETAILS_URL;
    return hostName;
  } else {
    // let hostName = window.location.origin;
    let hostName = process.env.REACT_APP_DETAILS_URL;

    return hostName;
  }
};

const getZinoDomainAppData = () => {
  let domainData = getStorageItem("Zino_domain_app_data");
  return JSON.parse(domainData);
};

const toastErrorMessageStyle = () => {
  return {
    style: {
      border: "1px solid red",
      padding: "16px",
      color: "red"
    },
    iconTheme: {
      primary: "red",
      secondary: "#FFFAEE"
    }
  };
};

const toastSuccessMessageStyle = () => {
  return {
    style: {
      border: "1px solid green",
      padding: "16px",
      color: "green"
    },
    iconTheme: {
      primary: "green",
      secondary: "#FFFAEE"
    }
  };
};

const detectDeviceType = () => {
  let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";
  if (environmentValue === "local") {
    // return /Mobile|Android|iPhone/i.test(navigator.userAgent) && !/iPad/i.test(navigator.userAgent)
    return /Mobile|Android|iPhone/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
  } else {
    let userAgent = "Desktop";
    let origin = window.location.origin;
    if (origin.includes("http://m.") || origin.includes("https://m.")) {
      userAgent = "Mobile";
    } else {
      // if (/Mobile|Android|iPhone/i.test(navigator.userAgent) && !/iPad/i.test(navigator.userAgent)) {
      if (/Mobile|Android|iPhone/i.test(navigator.userAgent)) {
        userAgent = "Mobile";
      } else {
        userAgent = "Desktop";
      }
    }
    return userAgent;
  }
};

export {
  getApiCallLocalPath,
  toastErrorMessageStyle,
  toastSuccessMessageStyle,
  getZinoDomainAppData,
  getApiCallHostPath,
  getApiCallDetailsPath,
  detectDeviceType
};

window.getApiCallLocalPath = getApiCallLocalPath;
